import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Pagination,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { LoadingButton } from '@mui/lab';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FutureInventory from './FutureInventory';
import uploadInventories from './uploadInventories';

export default function FutureInventories({
  inventories, setInventories, columnMappings, sortModel, filterModel, csvHeaders,
  fileName, open, setOpen, decimalSeparator,
}) {
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [status, setStatus] = useState('initial');

  const [globalState, globalActions] = useGlobal();
  const navigate = useNavigate();
  const firestore = useFirestore();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  // Calculate the containers to be displayed for the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentGroups = inventories.slice(startIndex, endIndex);

  const checkInventoryExists = async (name) => {
    const inventoriesRef = collection(
      firestore,
      `/organizations/${globalState.activeOrganization}/apps/inventory/inventories`,
    );
    const q = query(inventoriesRef, where('name', '==', name));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const upload = async () => {
    await uploadInventories(
      inventories,
      setStatus,
      globalActions,
      t,
      navigate,
      sortModel,
      filterModel,
      columnMappings,
      csvHeaders,
      fileName,
      checkInventoryExists,
      firestore,
      globalState,
      decimalSeparator,
    );
  };

  const changeInventoryName = (inventoryName, newName) => {
    const newInventories = inventories.map((inventory) => {
      if (inventory.inventoryName === inventoryName) {
        return { ...inventory, inventoryName: newName };
      }
      return inventory;
    });
    setInventories(newInventories);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1, cursor: 'pointer' }} onClick={() => setOpen(false)}>
            {t('back')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', mt: 3, p: 3,
      }}
      >
        <Typography sx={{ maxWidth: 1000 }}>{t('mulitple_inventories_info')}</Typography>
        {currentGroups.map((inventory) => (
          <Box key={`${inventory.inventoryName}}`}>
            <FutureInventory
              inventory={inventory}
              columnMappings={columnMappings}
              changeInventoryName={changeInventoryName}
            />
          </Box>
        ))}
        <Pagination
          count={Math.ceil(inventories.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
        <LoadingButton
          loading={status === 'loading'}
          size="large"
          sx={{ alignSelf: 'center' }}
          variant="contained"
          onClick={upload}
          disabled={status === 'loading'}
          endIcon={<CloudUploadIcon />}
        >
          {t('create_inventory.run_inventory')}
        </LoadingButton>
      </Box>
    </Dialog>
  );
}
