// FirestoreContext.js
import React, { createContext, useContext } from 'react';
import { getFirestore } from 'firebase/firestore';

const FirestoreContext = createContext(null);

export function FirestoreProvider({ app, children }) {
  const firestore = getFirestore(app);

  return (
    <FirestoreContext.Provider value={firestore}>
      {children}
    </FirestoreContext.Provider>
  );
}

export const useFirestore = () => useContext(FirestoreContext);
