import {
  Box, Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { dateFromTimeStampOrDate, userInventoryAppAccess } from 'components/utils';
import InventoryGrid from './InventoryGrid';
import {
  completedMapping,
} from './columnsUtils';
import { getColumnOptions } from './columnOptions';
import InventoryFileDropzone from './InventoryFileDropzone';
import uploadInventory from './uploadInventory';
import CreateInventoryAction from './CreateInventoryAction';
import MappingDialog from './mapping/MappingDialog';
import SelectMapping from './mapping/SelectMapping';

export default function CreateInventory() {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [containersArray, setContainersArray] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [mappingCompleted, setMappingCompleted] = useState(false);
  const [globalState] = useGlobal();
  const firestore = useFirestore();
  const [status, setStatus] = useState('initial');
  const navigate = useNavigate();
  const [inventoryName, setInventoryName] = useState('');
  const [estimatedDocumentSize, setEstimatedDocumentSize] = useState(0);
  const [fileName, setFileName] = useState('');
  const [fileTooBig, setFileTooBig] = useState(false);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);
  const [columnOptions, setColumnOptions] = useState(getColumnOptions(t));

  const mostRecentCsvMapping = useMemo(() => {
    const csvMappingsEntries = Object.entries(globalState.csvMappings);
    if (csvMappingsEntries.length === 0) {
      return {
        name: 'default',
        options: {
          columnMapping: {},
          columnDelimiter: ';',
          decimalSeparator: ',',
          startFromLine: 1,
          timestamp: new Date(),
        },
      };
    }
    const newMostRecentEntry = csvMappingsEntries.reduce((latest, current) => {
      const latestTimestamp = dateFromTimeStampOrDate(latest[1].timestamp);
      const currentTimestamp = dateFromTimeStampOrDate(current[1].timestamp);
      return latestTimestamp < currentTimestamp ? current : latest;
    }, ['default', { timestamp: new Date(0) }]);
    return {
      name: newMostRecentEntry[0],
      options: newMostRecentEntry[1],
    };
  }, [globalState.csvMappings]);

  const [currentCsvMapping, setCurrentCsvMapping] = useState(mostRecentCsvMapping);
  const [possibleColumnMapping, setPossibleColumnMapping] = useState({});

  const updateColumnOptions = (columnName, newOptions) => {
    setColumnOptions((prevOptions) => {
      const currentOptions = prevOptions.find((option) => option.name === columnName);
      const isDifferent = JSON.stringify(currentOptions) !== JSON.stringify({ name: columnName, ...newOptions });

      // If there are no differences, return prevOptions to avoid unnecessary state update
      if (!isDifferent) return prevOptions;

      const updatedOptions = prevOptions.map((option) => {
        if (option.name === columnName) {
          return { ...option, ...newOptions };
        }
        return option;
      });

      return updatedOptions;
    });
  };

  const onFilteringChange = (model) => {
    setFilterModel(model);
  };
  const onSortingChange = (model) => {
    setSortModel(model);
  };

  const columnMappingRelativeToHeaders = (columnMapping, headers) => columnOptions.reduce((acc, col) => {
    const {
      requiresExistingColumn, name, defaultValue, type, cellType,
    } = col;

    const storedCsvName = columnMapping[name]?.csvName;
    const isInHeaders = Array.isArray(storedCsvName)
      ? storedCsvName.every((eachName) => headers.includes(eachName))
      : headers.includes(storedCsvName);

    let csvName;
    if (isInHeaders || !requiresExistingColumn) {
      csvName = storedCsvName || defaultValue;
    } else {
      csvName = defaultValue;
    }

    acc[name] = {
      csvName,
      type,
      cellType,
      unit: (isInHeaders || !requiresExistingColumn) ? columnMapping[name]?.unit || '' : '',
    };

    return acc;
  }, {});

  const processContainersArray = (dataArray) => {
    setMappingCompleted(false);
    setFileTooBig(false);
    const headersFromCsv = dataArray[0];
    setCsvHeaders(headersFromCsv);
    setPossibleColumnMapping(columnMappingRelativeToHeaders(
      currentCsvMapping.options.columnMapping,
      headersFromCsv,
    ));
    let estimatedSize = 0;

    const rows = dataArray.slice(1).map((row, index) => {
      const rowData = userInventoryAppAccess(globalState.accessGrantNames) === 'full'
        ? { abvStatus: 'toDo', volumeStatus: 'toDo' } : {};
      row.forEach((cell, idx) => {
        rowData[headersFromCsv[idx]] = cell;
        // Estimate size: field name size + value size (assuming UTF-8 encoding)
        estimatedSize += new Blob([headersFromCsv[idx]]).size + new Blob([cell]).size;
      });
      return { id: index, ...rowData };
    });

    // Adding overhead for document and fields
    const firestoreOverhead = 32; // Adjust this based on your understanding of Firestore's overhead
    estimatedSize += firestoreOverhead + headersFromCsv.length * firestoreOverhead;
    // Safety Margin
    const safetyMargin = 1024 * 300; // 300 KiB safety margin
    // Total Estimated Size with Safety Margin
    estimatedSize += safetyMargin;

    setContainersArray(rows);
    setEstimatedDocumentSize(estimatedSize);
    // Check if estimated size exceeds Firestore's limit (1 MiB)
    if (estimatedSize > 1048576) {
      globalActions.setSnackbarMessage({ message: t('create_inventory.file_too_big'), severity: 'error' });
      setFileTooBig(true);
    } else {
      setOpenDialog(true);
    }
  };

  const cancelledMapping = () => {
    setOpenDialog(false);
  };

  const checkInventoryExists = async (name) => {
    const inventoriesRef = collection(
      firestore,
      `/organizations/${globalState.activeOrganization}/apps/inventory/inventories`,
    );
    const q = query(inventoriesRef, where('name', '==', name));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const updateSelectedMapping = (csvMappingName) => {
    const selectedMapping = globalState.csvMappings[csvMappingName];
    setCurrentCsvMapping({ name: csvMappingName, options: selectedMapping });
    setPossibleColumnMapping(columnMappingRelativeToHeaders(selectedMapping.columnMapping, csvHeaders));
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
    }}
    >
      <Typography variant="h5" component="div" sx={{ mt: 2 }}>
        {t('create_inventory.plan_inventory')}
      </Typography>
      <Typography component="div" sx={{ mb: 2, textAlign: 'left' }}>
        {t('create_inventory.plan_inventory_info')}
      </Typography>

      <SelectMapping
        currentCsvMapping={currentCsvMapping}
        callback={updateSelectedMapping}
      />

      <InventoryFileDropzone
        currentCsvMapping={currentCsvMapping}
        setCurrentCsvMapping={setCurrentCsvMapping}
        callback={(dataArray, name) => {
          setFileName(name);
          processContainersArray(dataArray);
        }}
        errorHandler={(err) => globalActions
          .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' })}
      />
      <Typography variant="caption" color={fileTooBig ? 'error' : ''}>
        {t('create_inventory.estimated_data_size', {
          estimatedSize: (estimatedDocumentSize / (1024 * 1024)).toFixed(2),
          maxSize: 1,
        })}
      </Typography>

      <MappingDialog
        columnOptions={columnOptions}
        csvHeaders={csvHeaders}
        currentCsvMapping={currentCsvMapping}
        setCurrentCsvMapping={setCurrentCsvMapping}
        possibleColumnMapping={possibleColumnMapping}
        setPossibleColumnMapping={setPossibleColumnMapping}
        updateSelectedMapping={updateSelectedMapping}
        openDialog={openDialog}
        updateColumnOptions={updateColumnOptions}
        completedMapping={() => completedMapping(
          t,
          firestore,
          containersArray,
          currentCsvMapping,
          setCurrentCsvMapping,
          possibleColumnMapping,
          columnOptions,
          setContainersArray,
          globalState,
          globalActions,
          setMappingCompleted,
          setOpenDialog,
        )}
        cancelledMapping={cancelledMapping}
      />

      {mappingCompleted && (
      <Typography sx={{ mt: 2 }}>
        {t('inventory_grid.wysiwyg')}
      </Typography>
      )}

      {mappingCompleted && (
        <InventoryGrid
          rows={containersArray}
          setRows={setContainersArray}
          onRowsChange={(updatedRows) => setContainersArray(updatedRows)}
          columnMappings={possibleColumnMapping}
          onFilteringChange={onFilteringChange}
          onSortingChange={onSortingChange}
        />
      )}

      {mappingCompleted && (
        <CreateInventoryAction
          inventoryName={inventoryName}
          setInventoryName={setInventoryName}
          status={status}
          containersArray={containersArray}
          columnMappings={possibleColumnMapping}
          sortModel={sortModel}
          filterModel={filterModel}
          csvHeaders={csvHeaders}
          fileName={fileName}
          checkInventoryExists={checkInventoryExists}
          decimalSeparator={currentCsvMapping.options.decimalSeparator}
          uploadInventory={() => uploadInventory(
            inventoryName,
            setStatus,
            globalActions,
            t,
            navigate,
            containersArray,
            sortModel,
            filterModel,
            possibleColumnMapping,
            csvHeaders,
            fileName,
            checkInventoryExists,
            firestore,
            globalState,
            currentCsvMapping.options.decimalSeparator,
          )}
        />
      )}
    </Box>
  );
}
