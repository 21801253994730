import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import alcoholUnitRelatedsFrom from 'components/units/UnitsRelatedFrom';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import { Button, Paper } from '@mui/material';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMenu from 'menu-actions/useMenu';
import { getErrorMessage } from 'components/errorUtils';
import useGlobal from '../../../global-state/store';
import {
  NumberInput,
  LabelInputSelect,
} from '../BoxetteInputs';
import decimalsMask from '../DecimalsMask';
import validationSchemaLaying from './ValidationSchemaLaying';
import InventoryItem from '../InventoryItem';
import ChangeFillingDialog from '../ChangeFillingDialog';
import EditableField from '../EditableField';
import { validateFieldsAtSubmit } from './validateFieldsAtSubmit';
import { getDefaultFormValues } from './getDefaultFormValues';
import { FormValues, OtherContainerOperationProps } from './types';

export default function OtherContainerOperation({
  itemData, columnMappings, recordOperation, nextOperation,
  previousItemFormValues, inventoryData, setInventoryData,
}: OtherContainerOperationProps) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [calculating, setCalculating] = useState(false);
  const fillingStatus = fillingStatusFromValue(itemData[columnMappings.filling.csvName]);
  const containerType = 'other';

  const isFullOrEmpty = ['full', 'empty'].includes(fillingStatus);
  const isFull = fillingStatus === 'full';
  const isEmpty = fillingStatus === 'empty';
  const { dispatchMenuActions } = useMenu();
  const [openChangeFilling, setOpenChangeFilling] = useState(false);

  const handleNext = () => {
    nextOperation();
  };

  const submitRecord = async (values: FormValues, proceedToNext: boolean) => {
    setCalculating(true);
    try {
      await recordOperation(values, proceedToNext);
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${errorMessage}`, severity: 'warning' });
      throw err;
    } finally {
      setCalculating(false);
    }
  };

  const submit = async (values: FormValues) => {
    if (await validateFieldsAtSubmit(values, columnMappings, fillingStatus, containerType, globalActions, t)) {
      await submitRecord(values, false);
    }
  };

  const handleSubmitAndNext = async () => {
    if (await validateFieldsAtSubmit(formik.values, columnMappings, fillingStatus, containerType, globalActions, t)) {
      await submitRecord(formik.values, true);
    }
  };

  const [alcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
    ),
  );

  const initialValues: FormValues = getDefaultFormValues(
    t,
    itemData,
    columnMappings,
    globalState,
    previousItemFormValues,
    fillingStatusFromValue,
  );

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: validationSchemaLaying(t, alcoholUnitRelateds),
    onSubmit(values) {
      return submit(values);
    },
  });

  const handleLiquidVolumeChange = (value: number) => {
    formik.setFieldValue('liquidVolume.value', value, false);
  };

  const reportChangeFillingAction = useCallback(() => ({
    name: t('operation.modify_filling'),
    callback: () => setOpenChangeFilling(true),
  }), [t]);

  useEffect(() => {
    dispatchMenuActions({ type: 'add', actions: [reportChangeFillingAction()] });
  }, [dispatchMenuActions, reportChangeFillingAction]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 600,
      }}
    >
      <Paper elevation={0} sx={{ px: 3, py: 2 }}>
        <InventoryItem
          onClick={undefined}
          itemData={itemData}
          columnMappings={columnMappings}
          showFillingLabel
          containerABVStatus={itemData.abvStatus}
          containerVolumeStatus={itemData.volumeStatus}
        />
      </Paper>
      <EditableField
        formik={formik}
        fieldKey="totalCapacity"
        translationKey="barrel_inventory.column_options.totalCapacity"
        dynamicUnitName={dynamicUnitName}
        decimalsMask={decimalsMask}
      />
      <EditableField
        formik={formik}
        fieldKey="usableCapacity"
        translationKey="barrel_inventory.column_options.usableCapacity"
        dynamicUnitName={dynamicUnitName}
        decimalsMask={decimalsMask}
      />
      { isFullOrEmpty && (
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1,
      }}
      >
        <Typography component="span">{`${t('ullaging.result_volume')} : `}</Typography>
        <Typography component="span">
          {isFull
            ? `${itemData[columnMappings.usableCapacity.csvName]} `
                + `${dynamicUnitName(columnMappings.usableCapacity.unit)} `
            : `0 ${dynamicUnitName(columnMappings.usableCapacity.unit)} `}
          <span style={{ fontWeight: 'bold' }}>
            {t(`inventory_item.filling.${fillingStatus}`)}
          </span>
        </Typography>
      </Box>
      )}

      <Paper
        elevation={0}
        sx={{
          px: { xs: 1, md: 3 },
          py: 2,
          border: 1,
          borderColor: 'secondary.main',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            position: 'absolute',
            top: 2,
            right: 10,
          }}
        >
          Volume
        </Typography>

        { !isFullOrEmpty && (
          <LabelInputSelect
            label={
              <Typography component="span">{t('ullaging.liquid_volume')}</Typography>
                    }
            input={(
              <NumberInput
                id="liquidVolume.value"
                disabled={isEmpty}
                name="liquidVolume.value"
                onValueChangeCallback={handleLiquidVolumeChange}
                placeholder="ex: 12.2"
                value={formik.values.liquidVolume.value}
                decimalScale={decimalsMask[formik.values.liquidVolume.unit as keyof typeof decimalsMask]}
                allowNegative
                onBlur={formik.handleBlur}
              />
            )}
            error={formik.touched.liquidVolume?.value && formik.errors.liquidVolume?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.liquidVolume?.value}
            </Typography>
            )}
            select={(
              <Typography component="span" sx={{ p: 2 }}>
                {dynamicUnitName(formik.values.liquidVolume.unit)}
              </Typography>
            )}
          />
        )}

        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.liquid_temp')}</Typography>
          }
          input={(
            <NumberInput
              id="volumeTemp.value"
              disabled={isEmpty}
              name="volumeTemp.value"
              onValueChangeCallback={(value: number) => formik.setFieldValue('volumeTemp.value', value)}
              placeholder="ex: 12.2"
              value={formik.values.volumeTemp.value}
              decimalScale={decimalsMask[formik.values.volumeTemp.unit as keyof typeof decimalsMask]}
              allowNegative
              onBlur={formik.handleBlur}
            />
          )}
          error={formik.touched.volumeTemp?.value && formik.errors.volumeTemp?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.volumeTemp?.value}
            </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.volumeTemp.unit)}
            </Typography>
          )}
        />

      </Paper>

      <Paper
        elevation={0}
        sx={{
          px: { xs: 1, md: 3 },
          py: 2,
          border: 1,
          borderColor: 'ourOrange.main',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >

        <Typography
          variant="caption"
          sx={{
            fontWeight: 'bold',
            color: 'ourOrange.main',
            position: 'absolute',
            top: 2,
            right: 10,
          }}
        >
          {t('inventory_item.status_caption_label.abvStatus')}
        </Typography>
        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.measured_tav')}</Typography>
          }
          input={(
            <NumberInput
              id="tav.value"
              disabled={isEmpty}
              onValueChangeCallback={(value: number) => formik.setFieldValue('tav.value', value)}
              name="tav.value"
              placeholder="ex: 56.88"
              value={formik.values.tav.value}
              decimalScale={decimalsMask[formik.values.tav.unit as keyof typeof decimalsMask]}
              onBlur={formik.handleBlur}
            />
          )}
          error={formik.touched.tav?.value && formik.errors.tav?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.tav?.value}
            </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.tav.unit)}
            </Typography>
          )}
        />

        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.measured_temp')}</Typography>
          }
          input={(
            <NumberInput
              id="tavTemp.value"
              disabled={isEmpty}
              name="tavTemp.value"
              onValueChangeCallback={(value: number) => formik.setFieldValue('tavTemp.value', value)}
              placeholder="ex: 12.2"
              value={formik.values.tavTemp.value}
              decimalScale={decimalsMask[formik.values.tavTemp.unit as keyof typeof decimalsMask]}
              allowNegative
              onBlur={formik.handleBlur}
            />
          )}
          error={formik.touched.tavTemp?.value && formik.errors.tavTemp?.value && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.tavTemp?.value}
            </Typography>
          )}
          select={(
            <Typography component="span" sx={{ p: 2 }}>
              {dynamicUnitName(formik.values.tavTemp.unit)}
            </Typography>
          )}
        />
      </Paper>

      {isEmpty && (
        <Button
          onClick={handleSubmitAndNext}
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
        >
          {t('inventory_item.validate_and_continue')}
        </Button>
      )}
      {isEmpty && (
      <Button
        type="submit"
        variant="contained"
        endIcon={<DoneIcon />}
      >
        {t('validate')}
      </Button>
      )}

      {!isEmpty && (
        <LoadingButton
          variant="contained"
          loading={calculating}
          disabled={!formik.isValid}
          onClick={handleSubmitAndNext}
          endIcon={<ArrowForwardIosIcon />}
        >
          {t('inventory_item.record_and_continue')}
        </LoadingButton>
      )}
      {!isEmpty && (
        <LoadingButton
          variant="contained"
          type="submit"
          loading={calculating}
          disabled={!formik.isValid}
          endIcon={<DoneIcon />}
        >
          {t('validate')}
        </LoadingButton>
      )}

      <Button variant="outlined" onClick={handleNext} endIcon={<ArrowForwardIosIcon />}>
        {t('inventory_item.next_container')}
      </Button>
      <ChangeFillingDialog
        open={openChangeFilling}
        setOpen={setOpenChangeFilling}
        itemData={itemData}
        setInventoryData={setInventoryData}
        inventoryData={inventoryData}
      />
    </Box>
  );
}
