import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import {
  collection, deleteDoc, doc, getDocs, updateDoc, writeBatch, getDoc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { httpsCallable } from 'firebase/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import useMenu from 'menu-actions/useMenu';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import syncContainersArray from 'utils/syncContainersArray';
import InventoryGrid from '../inventory/InventoryGrid';
import InventoryIncidentsWarning from './InventoryIncidentsWarning';
import { calculateTotalAP, calculateTotalEvaporation, runConversions } from './runConversions';

export default function InventoryDetailRouter() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.inventoryId) {
      navigate('/admin/inventories');
    }
  }, [location.state?.inventoryId, navigate]);

  if (!location.state?.inventoryId) {
    return <CircularProgress sx={{ alignSelf: 'center' }} />;
  }

  return <InventoryDetailLoader inventoryId={location.state.inventoryId} />;
}

function InventoryDetailLoader({ inventoryId }) {
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const [inventory, setInventory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const inventoryRef = doc(
          firestore,
          `/organizations/${globalState.activeOrganization}/apps/inventory/inventories/${inventoryId}`,
        );
        const inventorySnap = await getDoc(inventoryRef);
        if (inventorySnap.exists()) {
          setInventory({ ...inventorySnap.data(), id: inventorySnap.id });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inventory:', error);
        setLoading(false);
      }
    };

    fetchInventory();
  }, [firestore, globalState.activeOrganization, inventoryId]);

  if (loading) {
    return <CircularProgress sx={{ alignSelf: 'center' }} />;
  }

  if (!inventory) {
    return <Typography>Inventory not found</Typography>;
  }

  return (
    <InventoryDetailContent
      inventory={inventory}
      inventoryRef={doc(
        firestore,
        `/organizations/${globalState.activeOrganization}/apps/inventory/inventories/${inventoryId}`,
      )}
    />
  );
}

function InventoryDetailContent({
  inventory, inventoryRef,
}) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const laboxAutomatedAlcoApiCall = httpsCallable(functions, 'laboxAutomatedAlcoApiCall2ndGen');
  const [loading, setLoading] = useState(false);
  const [containersArray, setContainersArray] = useState(inventory.containersArray);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { dispatchMenuActions } = useMenu();

  const totalEvaporation = useMemo(() => {
    if (containersArray) {
      return calculateTotalEvaporation(containersArray, inventory.columnMappings);
    }
    return {};
  }, [containersArray, inventory.columnMappings]);

  const totalAP = useMemo(() => {
    if (containersArray) {
      return calculateTotalAP(containersArray, inventory.columnMappings);
    }
    return {};
  }, [containersArray, inventory.columnMappings]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteDoc(inventoryRef);
      globalActions
        .setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
      navigate('/admin/inventories');
    } catch (err) {
      globalActions
        .setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  }, [globalActions, inventoryRef, navigate, t]);

  const handleArchive = useCallback(async () => {
    try {
      const orgPath = `/organizations/${globalState.activeOrganization}/apps/inventory`;
      const inventoryPath = `${orgPath}/inventories/${inventory.id}`;
      const archivePath = `${orgPath}/archivedInventories/${inventory.id}`;

      const batch = writeBatch(firestore);
      const originalDocRef = doc(firestore, inventoryPath);
      const archiveDocRef = doc(firestore, archivePath);

      batch.set(archiveDocRef, { ...inventory });

      // If the document has subcollections, you need to copy each one
      const subcollections = ['records'];
      const subcollectionPromises = subcollections.map(async (subcollection) => {
        const subcollectionRef = collection(firestore, `${inventoryPath}/${subcollection}`);
        const snapshot = await getDocs(subcollectionRef);
        snapshot.forEach((document) => {
          batch.set(doc(firestore, `${archivePath}/${subcollection}/${document.id}`), document.data());
        });
      });
      await Promise.all(subcollectionPromises);

      batch.delete(originalDocRef);
      await batch.commit();
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
      navigate('/admin/inventories');
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  }, [firestore, globalActions, globalState.activeOrganization, inventory, navigate, t]);

  const deleteInventoryAction = useCallback(() => ({
    name: t('delete'),
    callback: () => handleDelete(),
  }), [handleDelete, t]);

  const archiveInventoryAction = useCallback(() => ({
    name: t('archive'),
    callback: () => handleArchive(),
  }), [handleArchive, t]);

  const syncInventoryAction = useCallback(() => ({
    name: t('sync_inventory'),
    callback: async () => {
      try {
        await syncContainersArray(firestore, globalState.activeOrganization, inventory.id, setContainersArray);
        globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
      } catch (err) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
        throw err;
      }
    },
  }), [firestore, globalState.activeOrganization, inventory.id, setContainersArray, t, globalActions]);

  useEffect(() => {
    dispatchMenuActions({
      type: 'set',
      actions: [deleteInventoryAction(), archiveInventoryAction(), syncInventoryAction()],
    });
  }, [archiveInventoryAction, deleteInventoryAction, syncInventoryAction, dispatchMenuActions, inventory, navigate]);

  const handleRunConversions = async () => runConversions(
    setLoading,
    selectedRowIds,
    globalActions,
    containersArray,
    inventory,
    t,
    laboxAutomatedAlcoApiCall,
    setContainersArray,
    updateFirestoreDocument,
  );

  const updateFirestoreDocument = async (updatedContainers, severity, message, duration) => {
    try {
      await updateDoc(inventoryRef, { containersArray: updatedContainers });
      globalActions.setSnackbarMessage({ message, severity, duration });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  const finishInventory = async () => {
    try {
      await updateDoc(inventoryRef, { status: 'done' });
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  const lockInventory = async () => {
    try {
      await updateDoc(inventoryRef, { status: 'locked' });
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  const unlockInventory = async () => {
    try {
      await updateDoc(inventoryRef, { status: 'done' });
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  const readyForReviewInventory = async () => {
    try {
      await updateDoc(inventoryRef, { status: 'readyForReview' });
      globalActions.setSnackbarMessage({ message: `${t('success')}`, severity: 'success', duration: 2000 });
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err}`, severity: 'error' });
      throw err;
    }
  };

  const handleRowsChange = async (updatedRows) => {
    updateDoc(inventoryRef, {
      containersArray: updatedRows,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

      <InventoryIncidentsWarning inventory={inventory} />

      <Typography
        variant="h6"
      >
        {t('inventory')}
        {' : '}
        {inventory.name}
      </Typography>
      <Typography variant="h8">
        {`${t('status')} : `}
        {t(`inventory_detail.status.${inventory.status}`)}
      </Typography>

      <InventoryGrid
        inventory={inventory}
        editable={!['archived', 'locked'].includes(inventory.status)}
        onRowsChange={handleRowsChange}
        rows={containersArray}
        setRows={setContainersArray}
        columnMappings={inventory.columnMappings}
        onRowsSelected={(rows) => setSelectedRowIds(rows)}
        originalDataColumnsOrder={inventory.originalDataColumnsOrder}
      />

      {totalEvaporation.totalEvaporation && (
        <Box sx={{
          display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', gap: 1,
        }}
        >
          <Typography variant="body2">
            {t('total_evaporation_info')}
            {' : '}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {totalEvaporation.totalEvaporation}
            {' '}
            {dynamicUnitName(totalEvaporation.unit)}
          </Typography>
          <Typography variant="body2">
            {t('either')}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {totalEvaporation.totalPercentEvaporation}
            {' % '}
          </Typography>
        </Box>
      )}

      {totalEvaporation.totalEvaporation && (
        <Box sx={{
          display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', gap: 1,
        }}
        >
          <Typography variant="body2">
            {t('total_ap_info')}
            {' : '}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {totalAP.totalAP}
            {' '}
            {dynamicUnitName(totalAP.unit)}
          </Typography>
        </Box>
      )}

      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: 1,
      }}
      >
        {['initial', 'inProgress', 'readyForReview', 'done'].includes(inventory.status) && (
          <LoadingButton
            size="large"
            variant="contained"
            loading={loading}
            onClick={handleRunConversions}
            endIcon={<PlayCircleOutlineIcon />}
          >
            {t('automated_conversion.run_conversion')}
          </LoadingButton>
        )}
        {['initial', 'inProgress', 'readyForReview'].includes(inventory.status) && (
          <Button
            size="small"
            color="ourOrange"
            variant="outlined"
            onClick={finishInventory}
            endIcon={<CheckCircleOutlineIcon />}
          >
            {t('inventory_detail.finish_inventory')}
          </Button>
        )}

        {inventory.status === 'done' && (
          <Button
            size="small"
            color="ourOrange"
            variant="outlined"
            onClick={readyForReviewInventory}
            endIcon={<RestartAltIcon />}
          >
            {t('inventory_detail.reopen_inventory')}
          </Button>
        )}

        {inventory.status === 'done' && (
          <Button
            size="small"
            color="ourOrange"
            variant="outlined"
            onClick={lockInventory}
            endIcon={<LockIcon />}
          >
            {t('lock')}
          </Button>
        )}
        {inventory.status === 'locked' && (
          <Button
            size="small"
            color="ourOrange"
            variant="outlined"
            onClick={unlockInventory}
            endIcon={<LockOpenIcon />}
          >
            {t('unlock')}
          </Button>
        )}
      </Box>
    </Box>
  );
}
