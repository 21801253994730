import { isFirestoreEqual } from 'components/utils';

export const setCookiesAccepted = (store, boolean) => {
  store.setState({ cookiesAccepted: boolean });
};

export const setUserUnits = (store, units) => {
  store.setState({ userUnits: units });
};

export const setClientPath = (store, path) => {
  store.setState({ clientPath: path });
};

export const setNetworkState = (store, state) => {
  store.setState({ networkState: state });
};

export const setSnackbarMessage = (store, options = { message: '', severiy: 'info', duration: 15000 }) => {
  store.setState({
    globalSnackbar: {
      display: true,
      message: options.message,
      severity: options.severity,
      displayDuration: options.duration,
    },
  });
};

export const closeSnackbar = (store) => {
  store.setState({ globalSnackbar: { display: false, message: '', severity: 'info' } });
};

export const setUserRoles = (store, anArray) => {
  store.setState({ roles: anArray });
};

export const setUserStatus = (store, status) => {
  store.setState({ userStatus: status });
};

export const setActiveOrganization = (store, organization) => {
  store.setState({ activeOrganization: organization });
};

export const setCsvMappings = (store, aMap) => {
  store.setState({ csvMappings: aMap });
};

export const setAccessGrants = (store, anArray) => {
  store.setState({ accessGrants: anArray });
};

export const setAccessGrantNames = (store, anArray) => {
  store.setState({ accessGrantNames: anArray });
};

export const updateLocalInventory = (store, inventoryId, updates) => {
  const currentState = store.state.localInventoryUpdates || {};

  const newState = {
    ...(currentState.localInventoryUpdates || {}),
    [inventoryId]: {
      ...(currentState.localInventoryUpdates?.[inventoryId] || {}),
      ...updates,
    },
  };

  store.setState({ localInventoryUpdates: newState });
};

export const cleanupSyncedInventoryUpdates = (store, firestoreDoc) => {
  const currentState = store.state.localInventoryUpdates || {};

  if (Object.keys(currentState).length === 0) {
    return;
  }

  const inventoryId = firestoreDoc.id;
  const localUpdates = currentState[inventoryId];

  if (!localUpdates?.originalServerState) return;

  const isInSync = Object.entries(localUpdates.originalServerState)
    .every(([key, value]) => isFirestoreEqual(value, firestoreDoc.data()[key]));

  if (isInSync) {
    const { [inventoryId]: _, ...remainingUpdates } = currentState;
    store.setState({ localInventoryUpdates: remainingUpdates });
  }
};

export const clearLocalInventoryUpdates = (store) => {
  store.setState({ localInventoryUpdates: {} });
};

export const clearLocalInventoryUpdate = (store, inventoryId) => {
  const currentState = store.state.localInventoryUpdates || {};
  const { [inventoryId]: _, ...remainingUpdates } = currentState;
  store.setState({ localInventoryUpdates: remainingUpdates });
};
