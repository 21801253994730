import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  doc,
  getDoc,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import {
  CircularProgress,
} from '@mui/material';
import InventoryItems from './InventoryItems';

export default function Inventory() {
  const location = useLocation();

  const navigate = useNavigate();

  const inventoryId = location.state?.inventory;

  useEffect(() => {
    if (inventoryId === undefined) {
      navigate('/');
    }
  }, [inventoryId, navigate]);

  if (!inventoryId) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}
      >
        <CircularProgress />
      </div>
    );
  }

  return <InventoryWithLocation inventoryId={inventoryId} />;
}

function InventoryWithLocation({ inventoryId }) {
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = useFirestore();
  const [globalState] = useGlobal();

  const inventoryRef = React.useMemo(
    () => doc(
      db,
      `organizations/${globalState.activeOrganization}/apps/inventory/inventories`,
      inventoryId,
    ),
    [db, globalState.activeOrganization, inventoryId],
  );

  const localUpdate = globalState.localInventoryUpdates[inventoryId];

  const fetchInventory = React.useCallback(async () => {
    const inventorySnapshot = await getDoc(inventoryRef);

    const firestoreData = { id: inventorySnapshot.id, ...inventorySnapshot.data() };
    const finalData = localUpdate ? { ...firestoreData, ...localUpdate } : firestoreData;

    setInventoryDetails(finalData);

    setLoading(false);
  }, [inventoryRef, localUpdate]);

  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  if (loading) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}
      >
        <CircularProgress />
      </div>
    );
  }
  return <InventoryItems inventoryDetails={inventoryDetails} setInventoryDetails={setInventoryDetails} />;
}
