import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContainerIcon({ status, showLabel = false, type = 'other' }) {
  const { t } = useTranslation();
  const getFillingPercentage = () => {
    switch (status) {
      case 'empty':
        return 0;
      case 'draining':
        return 50;
      case 'full':
        return 100;
      default:
        return null;
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case 'empty':
        return 'gray';
      case 'draining':
        return '#f4ad45';
      case 'full':
        return '#f4ad45';
      default:
        return '#gray';
    }
  };

  const layingBarrelStyle = {
    width: 25,
    height: 25,
    border: '3px solid gray',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const standingBarrelStyle = {
    width: 21,
    height: 21,
    borderRadius: '10% / 50%',
    outline: '3.5px solid gray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const otherContainerStyle = {
    width: 25,
    height: 25,
    border: '3px solid gray',
    borderRadius: '0%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  let containerStyle;
  if (type === 'standing') {
    containerStyle = standingBarrelStyle;
  } else if (type === 'laying') {
    containerStyle = layingBarrelStyle;
  } else {
    containerStyle = otherContainerStyle;
  }

  containerStyle = {
    ...containerStyle,
    background: `linear-gradient(to top, ${getBorderColor()} ${getFillingPercentage()}%, 
      transparent ${getFillingPercentage()}%)`,
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
    }}
    >
      <Box sx={containerStyle} />
      {showLabel && (
      <Typography sx={{ width: 50, textAlign: 'left' }}>
        {t(`inventory_item.filling.${status}`)}
      </Typography>
      )}
    </Box>
  );
}
