import React, { useState } from 'react';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  collection, getDocs, orderBy, query,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import {
  Button, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadInventoriesAsCsv, downloadInventoriesAsXlsx } from './exports';

function ExportAll({ listOfConditions, orderByAttribute, queryCount }) {
  const [open, setOpen] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const db = useFirestore();
  const collectionRef = collection(db, `organizations/${globalState.activeOrganization}/apps/inventory/inventories`);

  const handleOpen = async () => {
    setOpen(true);
  };

  const loadInventories = async () => {
    const countQuery = query(
      collectionRef,
      ...listOfConditions,
      orderBy('timestamp', 'desc'),
    );
    const querySnapshot = await getDocs(countQuery);
    const { docs } = querySnapshot;
    return docs.map((doc) => doc.data());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleXslxExport = async () => {
    setLoading(true);
    const allInventories = await loadInventories();
    await downloadInventoriesAsXlsx(
      allInventories,
      t('exported_inventory'),
      () => globalActions.setSnackbarMessage({
        message: t('success'),
        severity: 'success',
        duration: 2000,
      }),
      t,
    );
    setOpen(false);
    setLoading(false);
  };

  const handleCsvExport = async () => {
    setLoading(true);
    const allInventories = await loadInventories();
    await downloadInventoriesAsCsv(
      allInventories,
      t('exported_inventory'),
      () => globalActions.setSnackbarMessage({
        message: t('success'),
        severity: 'success',
        duration: 2000,
      }),
      t,
    );
    setOpen(false);
    setLoading(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        endIcon={<FileDownloadIcon />}
        disabled={!queryCount}
      >
        {t('export_all.title')}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>
            {t('export_all.info', { nbOfInventories: queryCount })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleCsvExport}
          >
            CSV
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleXslxExport}
          >
            XLSX
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ExportAll;
