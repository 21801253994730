import React, {
  createContext, useContext, useEffect, useState, useMemo,
  useCallback,
} from 'react';
import {
  getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider,
} from 'firebase/auth';

const AuthContext = createContext();

export function AuthProvider({ app, children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setInitializing(false);
    });
    return unsubscribe;
  }, [auth]);

  const signInWithGoogle = useCallback(() => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).finally(() => {
      setLoading(false);
    });
  }, [auth]);

  const signInCheck = useMemo(() => ({
    status: initializing ? 'loading' : 'success',
    data: {
      signedIn: !!currentUser,
      user: currentUser,
    },
  }), [currentUser, initializing]);

  const value = useMemo(() => ({
    currentUser,
    loading,
    initializing,
    signInWithGoogle,
    signInCheck,
  }), [currentUser, loading, initializing, signInWithGoogle, signInCheck]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useSignInCheck() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useSignInCheck must be used within an AuthProvider');
  }

  return {
    status: context.signInCheck.status,
    data: context.signInCheck.data,
  };
}
