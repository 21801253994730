import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import {
  collection, doc, addDoc,
} from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import useGlobal from 'global-state/store';
import useMenu from 'menu-actions/useMenu';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { fillingStatusFromValue } from 'components/utils/fillingType';
import { parseNumberOrEmptyString } from 'components/admin/inventories/utils';
import { typeFromValue } from 'components/utils/containerType';
import BarrelOperation from './barrel/BarrelOperation';
import ReportIncidentDialog from './ReportIncidentDialog';
import OtherContainerOperation from './otherContainer/OtherContainerOperation';
import { getDefaultFormValues as getBarrelDefaultFormValues } from './barrel/getDefaultFormValues';
import { getDefaultFormValues as getOtherContainerDefaultFormValues } from './otherContainer/getDefaultFormValues';

export default function Operation() {
  const location = useLocation();
  const {
    containerId, inventoryData, previousItemFormValues,
  } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (containerId === undefined || !inventoryData) {
      navigate('/');
    }
  }, [containerId, inventoryData, navigate]);

  if (containerId === undefined || !inventoryData) {
    return <CircularProgress />;
  }

  return (
    <OperationWithLocation
      key={containerId}
      inventoryData={inventoryData}
      containerId={containerId}
      previousItemFormValues={previousItemFormValues}
    />
  );
}

function OperationWithLocation({ inventoryData, previousItemFormValues, containerId }) {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { dispatchMenuActions } = useMenu();
  const { t } = useTranslation();
  const [openReportIncident, setOpenReportIncident] = useState(false);
  const analytics = getAnalytics();
  const [currentIventoryData, setCurrentIventoryData] = useState(inventoryData);
  const [currentContainerData, setCurrentContainerData] = useState();

  useEffect(() => {
    const newCurrentContainer = currentIventoryData.containersArray.find(
      (container) => container.id === containerId,
    );
    setCurrentContainerData(newCurrentContainer);
  }, [currentIventoryData, containerId]);

  const currentContainer = () => currentIventoryData.containersArray.find(
    (container) => container.id === containerId,
  );

  const reportIncidentAction = useCallback(() => ({
    name: t('operation.report_incident'),
    callback: () => setOpenReportIncident(true),
  }), [t]);

  useEffect(() => {
    dispatchMenuActions({ type: 'add', actions: [reportIncidentAction()] });
  }, [dispatchMenuActions, reportIncidentAction]);

  const getContainerId = (item) => {
    const ivtContainerIdMapping = currentIventoryData.columnMappings.ivtContainerId.csvName;
    if (Array.isArray(ivtContainerIdMapping)) {
      return ivtContainerIdMapping.map((colName) => item[colName]).join(' / ');
    }
    return item[ivtContainerIdMapping];
  };

  const nextOperation = (updatedData = ({
    updatedInventoryData: currentIventoryData,
  }), updatedPreviousItemFormValues = undefined) => {
    // Find the index of the current item in the inventory
    const currentIndex = updatedData.updatedInventoryData.containersArray.findIndex(
      (container) => getContainerId(container) === getContainerId(currentContainer()),
    );
    const getContainerUrlPathReference = (item) => {
      const ivtContainerIdMapping = currentIventoryData.columnMappings.ivtContainerId.csvName;
      if (Array.isArray(ivtContainerIdMapping)) {
        return ivtContainerIdMapping.map((colName) => item[colName]).join('_');
      }
      return item[ivtContainerIdMapping];
    };
    if (currentIndex >= 0 && currentIndex < updatedData.updatedInventoryData.containersArray.length - 1) {
      const nextItemData = updatedData.updatedInventoryData.containersArray[currentIndex + 1];

      navigate(`/inventory/operation/${getContainerUrlPathReference(nextItemData)}`, {
        state: {
          containerId: nextItemData.id,
          inventoryData: updatedData.updatedInventoryData,
          previousItemFormValues: updatedPreviousItemFormValues,
        },
        replace: true,
      });
    } else {
      navigate(-1);
    }
  };

  function calculateProgressPercentageABV(containersArray) {
    const totalItems = containersArray.length;
    const completedItems = containersArray.filter((container) => container.abvStatus === 'done').length;
    return (completedItems / totalItems) * 100;
  }

  function calculateProgressPercentageVolume(containersArray) {
    const totalItems = containersArray.length;
    const completedItems = containersArray.filter((container) => container.volumeStatus === 'done').length;
    return (completedItems / totalItems) * 100;
  }

  const recordOperation = async (values, processToNext) => {
    logEvent(analytics, 'recordOperation', {
      appName: 'Inventory',
      organization: globalState.activeOrganization,
    });

    const inventoryRef = doc(
      firestore,
      `/organizations/${globalState.activeOrganization}/apps/inventory/inventories/${currentIventoryData.id}`,
    );
    const recordsRef = collection(firestore, `${inventoryRef.path}/records`);

    const oldFormValues = containerType === 'standing' || containerType === 'laying'
      ? getBarrelDefaultFormValues(
        t,
        currentContainerData,
        currentIventoryData.columnMappings,
        globalState,
        null, // No previous values needed for old values
        fillingStatusFromValue,
      )
      : getOtherContainerDefaultFormValues(
        t,
        currentContainerData,
        currentIventoryData.columnMappings,
        globalState,
        null, // No previous values needed for old values
        fillingStatusFromValue,
      );

    // Add a new record directly without batch
    addDoc(recordsRef, {
      containerId: getContainerId(currentContainer()),
      formValues: values,
      oldFormValues,
      timeStamp: new Date(),
    });

    const newVolumeStatusDone = (fillingStatus) => {
      if (fillingStatus === 'full') {
        return !!values.volumeTemp.value;
      } if (fillingStatus === 'empty') {
        return true;
      }
      return !!values.liquidHeight.value || !!values.emptyHeight.value
        || !!values.liquidVolume.value || !!values.volumeTemp.value;
    };

    const newABVStatusDone = (fillingStatus) => {
      if (fillingStatus === 'empty') {
        return true;
      }
      return !!values.tav.value || !!values.tavTemp.value;
    };

    // Update the status of the container in containersArray
    const updatedContainersArray = currentIventoryData.containersArray.map((container) => {
      if (getContainerId(container) === getContainerId(currentContainer())) {
        const fillingStatus = fillingStatusFromValue(container[currentIventoryData.columnMappings.filling.csvName]);
        return {
          ...container,
          abvStatus: newABVStatusDone(fillingStatus) ? 'done' : 'toDo',
          volumeStatus: newVolumeStatusDone(fillingStatus) ? 'done' : 'toDo',
          [currentIventoryData.columnMappings.totalCapacity.csvName]:
            parseNumberOrEmptyString(values.totalCapacity.value),
          [currentIventoryData.columnMappings.usableCapacity.csvName]:
            parseNumberOrEmptyString(values.usableCapacity.value),
          [currentIventoryData.columnMappings.heightUnderBung.csvName]:
            parseNumberOrEmptyString(values.diameterBond.value),
          [currentIventoryData.columnMappings.liquidHeight.csvName]:
            parseNumberOrEmptyString(values.liquidHeight.value),
          [currentIventoryData.columnMappings.emptyHeight.csvName]:
            parseNumberOrEmptyString(values.emptyHeight.value),
          [currentIventoryData.columnMappings.liquidVolume.csvName]:
            parseNumberOrEmptyString(values.liquidVolume.value),
          [currentIventoryData.columnMappings.tav.csvName]:
            parseNumberOrEmptyString(values.tav.value),
          [currentIventoryData.columnMappings.tavTemperature.csvName]:
            parseNumberOrEmptyString(values.tavTemp.value),
          [currentIventoryData.columnMappings.volumeTemperature.csvName]:
            parseNumberOrEmptyString(values.volumeTemp.value),
        };
      }
      return container;
    });

    const progressABVPercentage = calculateProgressPercentageABV(updatedContainersArray);
    const progressVolumePercentage = calculateProgressPercentageVolume(updatedContainersArray);

    let newStatus = currentIventoryData.status;
    if (newStatus === 'initial') {
      newStatus = 'inProgress';
    } else if (newStatus === 'inProgress' && progressABVPercentage === 100 && progressVolumePercentage === 100) {
      newStatus = 'readyForReview';
    }

    const updates = {
      containersArray: updatedContainersArray,
      progressABVPercentage,
      progressVolumePercentage,
      status: newStatus,
    };

    globalActions.updateLocalInventory(currentIventoryData.id, updates);

    const completeInventoryUpdate = { ...currentIventoryData, ...updates };
    setCurrentIventoryData(completeInventoryUpdate);

    if (processToNext) {
      const updatedData = {
        updatedInventoryData: completeInventoryUpdate,
      };
      nextOperation(updatedData, values);
    } else {
      navigate(-1);
    }
  };

  const containerType = currentContainerData && currentIventoryData?.columnMappings?.type?.csvName
    ? typeFromValue(currentContainerData[currentIventoryData.columnMappings.type.csvName])
    : undefined;

  if (currentIventoryData) {
    return (
      <Box sx={{
        mt: 1,
      }}
      >
        {currentContainerData && (
          containerType === 'standing' || containerType === 'laying' ? (
            <BarrelOperation
              itemData={currentContainerData}
              setInventoryData={setCurrentIventoryData}
              columnMappings={currentIventoryData.columnMappings}
              recordOperation={recordOperation}
              nextOperation={nextOperation}
              previousItemFormValues={previousItemFormValues}
              inventoryData={currentIventoryData}
            />
          ) : (
            <OtherContainerOperation
              itemData={currentContainerData}
              setInventoryData={setCurrentIventoryData}
              columnMappings={currentIventoryData.columnMappings}
              recordOperation={recordOperation}
              nextOperation={nextOperation}
              previousItemFormValues={previousItemFormValues}
              inventoryData={currentIventoryData}
            />
          )
        )}
        <ReportIncidentDialog
          open={openReportIncident}
          setOpen={setOpenReportIncident}
          containerId={getContainerId(currentContainer())}
          inventoryId={inventoryData.id}
          inventoryName={inventoryData.name}
        />
      </Box>
    );
  }

  return null;
}
