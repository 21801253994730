import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  doc, getDoc,
  disableNetwork,
  enableNetwork,
  addDoc,
  collection,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { MenuProvider } from 'menu-actions/MenuContext';
import {
  BrowserRouter, Routes, Route, useNavigate, useLocation,
  Navigate,
} from 'react-router-dom';
import StoragePDFViewerPage from 'components/StoragePDFViewerPage';
import MyAccount from 'components/account/MyAccount';
import cgu from 'staticFiles/CGU.pdf';
import cgv from 'staticFiles/CGV.pdf';
import cgucgv from 'staticFiles/CGU+CGV.pdf';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import GlobalSnackbar from 'components/GlobalSnackbar';
import { Cookies, Contact } from 'labox-ws-commons';
import { getAnalytics } from 'firebase/analytics';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import LoadingPage from 'components/LoadingPage';
import privacyFR from 'staticFiles/privacy-policy-fr.pdf';
import privacyEN from 'staticFiles/privacy-policy-en.pdf';
import { useAuth, useSignInCheck } from 'firebaseHooks/AuthContext';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import TopButtons from './TopButtons';
import PDFView from './PDFView';
import SignInPage from './SignInPage';
import Admin from './admin/Admin';
import SelectInventory from './operation/SelectInventory';
import Inventory from './operation/Inventory';
import Operation from './operation/Operation';
import CreateInventory from './admin/inventory/CreateInventory';
import Inventories from './admin/inventories/Inventories';
import Incidents from './admin/incidents/Incidents';
import InventoryDetail from './admin/inventories/InventoryDetail';
import { getValidAccessGrants, userInventoryAppAccess } from './utils';
import NotFound from './NotFound';

export default function Home() {
  return <RemoteConfigStatus defaultComponent={<UserSetupMenuAndMainPage />} />;
}

function UserSetupMenuAndMainPage() {
  const { currentUser: user } = useAuth();
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { status: signInStatus, data: signInCheckResult } = useSignInCheck();

  const getAlcoholometryAccessGrantsCb = useCallback(async (activeOrganization) => getValidAccessGrants(
    db,
    user,
    activeOrganization,
  ), [db, user]);

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function getUser(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function getUserPrivateData(uid) {
      const userRef = doc(db, 'users', uid, 'private', 'data');
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function fetchMultipleTimesUser(uid) {
      let firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(1000);
        firestoreUser = await getUser(uid);
        if (!firestoreUser.exists()) {
          await await sleep(2000);
          firestoreUser = await getUser(uid);
        }
      }
      return firestoreUser;
    }

    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');
      await fetchMultipleTimesUser(user.uid);
      const firestoreUserPrivateData = await getUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      globalActions.setActiveOrganization(userPrivateData.activeOrganization);

      const accessGrants = await getAlcoholometryAccessGrantsCb(userPrivateData.activeOrganization);
      globalActions.setAccessGrants(accessGrants.validGrants);
      globalActions.setAccessGrantNames(accessGrants.uniqueGrantNames);

      const access = userInventoryAppAccess(accessGrants.uniqueGrantNames);

      if (userPrivateData.activeOrganization === '' || access === 'none') {
        return globalActions.setUserStatus('notYetSetup');
      }
      const orgaAppPath = `/organizations/${userPrivateData.activeOrganization}/apps/inventory`;
      const organizationAppRef = doc(db, orgaAppPath);
      const firestoreorganizationApp = await getDoc(organizationAppRef);
      const organizationAppData = firestoreorganizationApp.data();
      globalActions.setClientPath(orgaAppPath);
      if (!organizationAppData) {
        return globalActions.setUserStatus('notYetSetup');
      }
      if (organizationAppData.admins.includes(user.uid)) {
        globalActions.setUserRoles(['admin']);
      }
      // the following if case can be removed once all orgs have migrated to use csvMappings
      if (organizationAppData.csvMappings === undefined && organizationAppData.defaultBarrelCsvMapping !== undefined) {
        globalActions.setCsvMappings({
          default: {
            ...organizationAppData.defaultBarrelCsvMapping,
            timestamp: new Date(),
          },
        });
      }
      if (organizationAppData.csvMappings !== undefined) {
        globalActions.setCsvMappings(organizationAppData.csvMappings);
      }
      if (organizationAppData.gaugerCollaborations !== undefined) {
        globalActions.setGaugerCollaborations(organizationAppData.gaugerCollaborations);
      }
      return globalActions.setUserStatus('setupDone');
    }

    function setupFirestoreNetwork() {
      if (globalState.networkState === 'offline') {
        disableNetwork(db);
      }
    }

    if (signInStatus === 'success'
      && user?.uid !== undefined
      && globalState.userStatus === 'initial') {
      setupGlobalStateFromUser();
      setupFirestoreNetwork();
    } else if (signInStatus === 'success' && signInCheckResult.signedIn === false) {
      globalActions.setUserStatus('notSignedIn');
    }
  }, [db, getAlcoholometryAccessGrantsCb, globalActions, globalState.networkState,
    globalState.userStatus, user?.uid, signInStatus, signInCheckResult.signedIn]);

  window.ononline = (event) => {
    enableNetwork(db);
    globalActions.setNetworkState('online');
  };

  window.onoffline = (event) => {
    disableNetwork(db);
    globalActions.setNetworkState('offline');
  };

  if (signInStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return <MenuProvider app={<MainPage />} />;
}

function MainPage() {
  const [globalState] = useGlobal();
  const [displayAll, setdisplayAll] = useState(false);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {globalState.networkState === 'offline' && (
        <Alert
          sx={{
            padding: 1, mt: 1, justifyContent: 'center',
          }}
          severity="error"
          onClick={() => setdisplayAll(!displayAll)}
        >
          {t('offline')}
          <Button sx={{ ml: 1, height: 17 }} color="inherit" variant="outlined" size="small">
            {expandIcon()}
          </Button>
          <Collapse
            in={displayAll}
            sx={{
              width: '100%',
            }}
          >
            {t('offline_more')}
          </Collapse>
        </Alert>
        )}
        <Box sx={{
          minHeight: '80vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <GlobalSnackbar />
          <TopButtons />
          <MainPageContent />
        </Box>
        <Footer />
        <Cookies
          cookieName="agreed-to-cookies-on-labox-inventory"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function MainPageContent(props) {
  const { data: signInCheckResult } = useSignInCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!signInCheckResult?.signedIn === true
      && location.pathname !== '/contact'
      && location.pathname !== '/cgu'
      && location.pathname !== '/cgv'
      && location.pathname !== '/cgu-cgv'
      && location.pathname !== '/privacy-policy') {
      navigate('/signin');
    }
  }, [location.pathname, navigate, signInCheckResult?.signedIn]);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Inventory, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  const privacyPolicy = () => {
    if (t('locale') === 'fr') {
      return privacyFR;
    }
    return privacyEN;
  };

  const access = userInventoryAppAccess(globalState.accessGrantNames);

  if (['initial', 'loading'].includes(globalState.userStatus)) {
    return <LoadingPage />;
  }

  if (globalState.userStatus !== 'setupDone' || access === 'none') {
    return (
      <Routes>
        <Route path="/" element={<SelectInventory />} />
        <Route
          path="pdfViewer/*"
          element={<StoragePDFViewerPage />}
        />
        <Route exact path="account" element={<MyAccount />} />
        <Route exact path="signin" element={<SignInPage />} />
        <Route
          path="contact"
          element={(
            <Contact
              t={t}
              navigate={navigate}
              globalActions={globalActions}
              analytics={analytics}
              sendEmail={sendEmail}
              signInCheckResult={signInCheckResult}
            />
          )}
        />
        <Route path="cgu" element={<PDFView path={cgu} />} />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    );
  }

  if (access === 'full') {
    return <FullInventorySolutionRoutes sendEmail={sendEmail} privacyPolicy={privacyPolicy} />;
  }
  return <OnlyCalculationsSolutionRoutes sendEmail={sendEmail} privacyPolicy={privacyPolicy} />;
}

function FullInventorySolutionRoutes({ sendEmail, privacyPolicy }) {
  const { data: signInCheckResult } = useSignInCheck();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const analytics = getAnalytics();

  return (
    <Routes>
      <Route path="/" element={<SelectInventory />} />
      <Route path="inventory" element={<Inventory />} />
      <Route path="inventory/operation/:containerId" element={<Operation />} />
      <Route
        path="pdfViewer/*"
        element={<StoragePDFViewerPage />}
      />
      <Route exact path="account" element={<MyAccount />} />
      <Route exact path="signin" element={<SignInPage />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
          )}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="cgu-cgv" element={<PDFView path={cgucgv} />} />
      <Route path="admin" element={<Admin />} />
      <Route path="admin/createInventory" element={<CreateInventory />} />
      <Route path="admin/inventories" element={<Inventories />} />
      <Route path="admin/incidents" element={<Incidents />} />
      <Route path="admin/inventories/inventory" element={<InventoryDetail />} />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}

function OnlyCalculationsSolutionRoutes({ sendEmail, privacyPolicy }) {
  const { data: signInCheckResult } = useSignInCheck();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const analytics = getAnalytics();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/admin" replace />} />
      <Route exact path="account" element={<MyAccount />} />
      <Route exact path="signin" element={<SignInPage />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
        )}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="cgu-cgv" element={<PDFView path={cgucgv} />} />
      <Route path="admin" element={<Admin />} />
      <Route path="admin/createInventory" element={<CreateInventory />} />
      <Route path="admin/inventories" element={<Inventories />} />
      <Route path="admin/incidents" element={<Incidents />} />
      <Route path="admin/inventories/inventory" element={<InventoryDetail />} />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}
