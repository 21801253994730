import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {
  where,
} from 'firebase/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import OurPagination from 'components/OurPagination';
import moment from 'moment';
import { useAuth } from 'firebaseHooks/AuthContext';
import CreateInventoryButton from '../CreateInventoryButton';
import IncidentListItem from './IncidentListItem';
import Filters from '../inventories/Filters';

export default function Incidents() {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();
  const [queryCount, setQueryCount] = useState(undefined);

  const computeListOfConditions = useCallback((formikValues) => {
    const conditions = [
      where('timestamp', '<=', formikValues.endDate.toDate()),
      where('timestamp', '>=', formikValues.startDate.toDate()),
    ];
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    return moment().subtract(5, 'years').startOf('day');
  }

  function initialEndDate() {
    return moment().endOf('day');
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_incidents', {
        user_uid: user?.uid,
        appName: 'Inventory',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%',
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('incidents.title')}
      </Typography>
      {globalState.activeOrganization !== '' && (
        <OurPagination
          setQueryCount={setQueryCount}
          collectionPath={`organizations/${globalState.activeOrganization}/apps/inventory/incidents`}
          listOfConditions={listOfConditions}
          orderByAttribute="timestamp"
          filters={(
            <IncidentsListFilters
              queryCount={queryCount}
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
            />
              )}
          list={(
            <IncidentsList listOfConditions={listOfConditions} />
              )}
        />
      )}
      {globalState.activeOrganization === '' && <CircularProgress />}
      <CreateInventoryButton fab />
    </Box>
  );
}

function IncidentsListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formikInitValues,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <Filters formik={formik} datePickersLabel={t('admin.made')} />
    </Box>
  );
}

function IncidentsList({ docs, initSearch, listOfConditions }) {
  const handleDeleteItem = () => initSearch(listOfConditions);
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {docs.map((op, i) => (
            <div key={op.id}>
              <IncidentListItem
                incident={{ id: op.id, ...op.data() }}
                index={i}
                handleDeleteItem={handleDeleteItem}
              />
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
