import React, { useEffect, useState } from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { Container } from '@mui/material';
import { FirestoreProvider } from 'firebaseHooks/FirestoreContext';
import { FunctionsProvider } from 'firebaseHooks/FunctionsContext';
import { AuthProvider } from 'firebaseHooks/AuthContext';
import { AnalyticsProvider } from 'firebaseHooks/AnalyticsContext';
import { StorageProvider } from 'firebaseHooks/StorageContext';
import { RemoteConfigProvider } from 'firebaseHooks/RemoteConfigContext';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import CssBaseline from '@mui/material/CssBaseline';
import LoadingPage from 'components/LoadingPage';
import Home from 'components/Home';
import { initializeApp } from 'firebase/app';
import {
  initializeFirestore, persistentLocalCache, persistentSingleTabManager,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'inventory.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:cd60feb77e4ad128297452',
  measurementId: 'G-FM7D8XN6KB',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    info: {
      main: '#437A89',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    flashyGreen: {
      main: '#1db954',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    lightGray: {
      main: '#f0f0f0',
      contrastText: 'black',
    },
  },
});

// Initialize all Firebase services at once
function initializeFirebaseServices() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  // Initialize Firestore with proper settings
  const baseSettings = {
    localCache: persistentLocalCache({
      tabManager: persistentSingleTabManager({
        experimentalForceOwningTab: true,
        // eslint-disable-next-line no-undef
        forceOwnership: !globalThis.localStorage,
      }),
    }),
  };

  // Add long polling configuration
  const savedSetting = localStorage.getItem('forceLongPolling');
  const forceLongPolling = savedSetting === 'true';
  if (forceLongPolling) {
    baseSettings.experimentalForceLongPolling = true;
  } else {
    baseSettings.experimentalAutoDetectLongPolling = true;
  }

  const fsSettings = (process.env.NODE_ENV === 'development')
    ? {
      ...baseSettings,
      host: 'localhost:4401',
      ssl: false,
    } : baseSettings;

  const firestore = initializeFirestore(app, fsSettings);

  // Set up emulators in development
  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
  }

  return {
    app, auth, functions, storage, firestore,
  };
}

// Initialize services before rendering
const services = initializeFirebaseServices();

function App() {
  return (
    <div className="App" style={{ whiteSpace: 'pre-line' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container>
            <CssBaseline />
            <AuthProvider app={services.app}>
              <RemoteConfigProvider app={services.app}>
                <AnalyticsProvider app={services.app}>
                  <FirestoreProvider app={services.app}>
                    <FunctionsProvider app={services.app}>
                      <StorageProvider app={services.app}>
                        <Core />
                      </StorageProvider>
                    </FunctionsProvider>
                  </FirestoreProvider>
                </AnalyticsProvider>
              </RemoteConfigProvider>
            </AuthProvider>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

function Core() {
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    if (status === 'initial') {
      setStatus('loaded');
    }
  }, [status]);

  if (status !== 'loaded') {
    return <LoadingPage />;
  }

  return <Home />;
}

export default App;
